import { useState } from 'react';
import styled from 'styled-components';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  Button,
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import ContactForm from './ContactForm';

import spatitech_s from '../images/spatitechlogo_s.svg'
import spatitech_logo from '../images/logo2.svg'
import { isDesktop, isMobile } from 'react-device-detect';

function NavBar(props: { setLocale: (locale: string) => void; locale: string}) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar className='p-3'>
       { isDesktop ? 
        <div className='ml-0'>
            {props.locale === 'de' ? <Button outline className='m-1' onClick={() => { props.setLocale('en'); setIsOpen(false); }}>
              <img style={{ width: '3em' }}
                alt="United States"
                src="http://purecatamphetamine.github.io/country-flag-icons/3x2/GB.svg" />
            </Button> : <Button outline className='m-1' onClick={() => { props.setLocale('de'); setIsOpen(false); }}>
              <img style={{ width: '3em' }}
                alt="Deutschland"
                src="http://purecatamphetamine.github.io/country-flag-icons/3x2/DE.svg" />
            </Button>}
            </div> : null}
      { isMobile ? <img src={spatitech_s} alt="logo" style={{ height: '2em', marginLeft: isDesktop ? '0 !important' : '1em' }} /> : null}
      <img src={spatitech_logo} alt="logo" style={{ height: '2em', marginLeft: '1em' }} />
        <NavbarToggler onClick={toggle} >
        <LargeIcon2 className="bi-list"></LargeIcon2>
      </NavbarToggler>
      <Collapse isOpen={isOpen} navbar className='p-3'>
        <Nav className="me-auto" navbar>
          <NavItem>
            {isMobile ? <div>
              <Button outline className='m-1' onClick={() => { props.setLocale('de'); setIsOpen(false); }}>
              <img style={{ width: '3em' }}
                alt="Deutschland"
                src="http://purecatamphetamine.github.io/country-flag-icons/3x2/DE.svg" />
            </Button>
            <Button outline className='m-1' onClick={() => { props.setLocale('en'); setIsOpen(false); }}>
              <img style={{ width: '3em' }}
                alt="United States"
                src="http://purecatamphetamine.github.io/country-flag-icons/3x2/GB.svg" />
            </Button>
            </div> : null}
            <NavLink className='mb-3' style={{ fontWeight: 600, fontSize: 'x-large' }} href="/components/">
              <FormattedMessage id="navbar.signupNow" />
            </NavLink>
            <ContactForm />
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
}

export default NavBar;

const LargeIcon2 = styled.i`
  font-size: 1.5em;
  opacity: 0.5;
`
export const WhiteOutlineButton = styled(Button)`
  color: black;
  border: 1px solid white;
  opacity: 1;
`
