import ReactGA from 'react-ga4'
import SpatiTech from './components/SpatiTech'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

ReactGA.initialize("G-4WDT3JEDV4")

function App() {
  return (
    <div className="App">
      <SpatiTech />
    </div>
  );
}

export default App;
