import { useForm, ValidationError } from '@formspree/react';
import { Button, Col, Container, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';

function ContactFormInline() {
    const [state, handleSubmit] = useForm("xkndrkwe");
    const intl = useIntl()
    if (state.succeeded) {
        return <p><FormattedMessage id="contactForm.thankYou" /></p>;

    }
    return (
        <form onSubmit={handleSubmit}>
            <Container className='pt-3 pb-5'>
                <Row className='mb-3'>
                    <Col xs={8}>
                        <input style={{
                            width: '100%',
                            border: 'none',
                            padding: '10px'
                        }} id="email" type="email" name="email" placeholder={intl.formatMessage({ id: 'contactForm.yourEmail' })}
                            autoComplete="email" />
                    </Col>
                    <Col xs={4} style={{ backgroundColor: 'black' }}><Button style={{
                        backgroundColor: 'black',
                        border: 'none',
                        padding: '10px'
                    }} type="submit" disabled={state.submitting}><FormattedMessage id="contactForm.submit" /></Button>
                    </Col>
                </Row>
                <Row><ValidationError
                    prefix="Email"
                    field="email"
                    errors={state.errors}
                /></Row>
                <Row>
                </Row>
            </Container>
        </form>
    );
}

export default ContactFormInline;
