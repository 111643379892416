import { useForm, ValidationError } from '@formspree/react';
import { Button, Container, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';

function ContactForm() {
    const [state, handleSubmit] = useForm("xkndrkwe");
    const intl = useIntl()

    if (state.succeeded) {
        return <p><FormattedMessage id="contactForm.thankYou" /></p>;
    }
    return (
        <form onSubmit={handleSubmit}>
            <Container className='pt-3 pb-5'>
                <Row className='mb-3'>
                    <input
                        id="email"
                        type="email"
                        name="email"
                        placeholder={intl.formatMessage({ id: 'contactForm.yourEmail' })}
                        autoComplete="email"
                    />
                    <ValidationError
                        prefix="Email"
                        field="email"
                        errors={state.errors}
                    />
                </Row>
                <Row className='mb-3'>
                    <textarea id="message" placeholder={intl.formatMessage({ id: 'contactForm.optionalMessage' })} name="message" />
                    <ValidationError prefix="Message" field="message" errors={state.errors} />
                </Row>
                <Row>
                    <Button type="submit" disabled={state.submitting}><FormattedMessage id="contactForm.submit" /></Button>
                </Row>
            </Container>
        </form>
    );
}

export default ContactForm;
